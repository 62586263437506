<template>
  <div>
    <b-row>
      <b-col class="padding-element-div">
        DIVULGAR NO GUIA MÉDICO?
        <img src="@/assets/icons/notebook-check-outline-gray.svg" />
      </b-col>
      <b-col class="padding-element-div text-right">
        <b-form-checkbox
          class="ml-auto"
          switch
          v-model="specialty.publishMedicalGuide"
          @change="onEditeHandler(specialty)"
        >
          {{ specialty.publishMedicalGuide ? "Sim" : "Não" }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="padding-element-div">
        ESPECIALIDADE PRINCIPAL
        <img src="@/assets/icons/heart-pulse-gray.svg" />
      </b-col>
      <b-col class="padding-element-div text-right">
        {{ specialty.mainSpecialtyMedical ? "Sim" : "Não" }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { updateNotificationSpeciality } from "../../../../../services/medical-specialities/medical-specialities-services";
import TypeOcurrences from "../../../../../static-data/TypeOcurrences";
import {
  addOccurrenceStorage,
  createNewOcurrence,
} from "../../../../../utils/update-occurrences/occurrences-utils";
export default {
  props: ["specialties", "publishEvent"],
  components: {},
  mounted() {
    this.specialty = this.$props.specialties;
  },
  data() {
    return {
      specialty: {},
    };
  },
  methods: {
    onEditeHandler(item) {
      if (this.$store.getters.isAdmin) {
        return;
      }
      if (this.$props.publishEvent) this.$props.publishEvent(item);

      if (item.aborted == false) {
        updateNotificationSpeciality(item, this.$store.getters.getUserName);

        const newOcurrence = createNewOcurrence(
          TypeOcurrences.SPECIALITY,
          item.specialtyId,
          item.publishMedicalGuide,
          `Alteração da especialidade - ${item.specialtyDescription}`,
          item.publishMedicalGuide ? false : true
        );
        addOccurrenceStorage(newOcurrence);
      }
    },
  },
};
</script>

<style lang="scss">
.specialty.table td {
  border: none;
  padding: 0;
}
.specialty.table tr {
  padding: 1.5rem 1rem;
  border-top: 1px solid var(--light-0);
}

.specialty.table.b-table.b-table-stacked {
  & :first-child {
    border: none;
    border-color: transparent;
  }
}
.custom-accordion {
  padding: 1rem 0rem 0rem 0rem;
}
.padding-element-div {
  padding: 10px 0px 6px 15px;
}
.custom-control-label,
.custom-control-label {
  margin-top: 4px !important;
  padding-top: 0px;
}
</style>
