import TypeRequestedFlow from "../../static-data/TypeRequestedFlow";
import FormRequestAction from "../../static-data/FormRequestAction";
import ServiceRequestType from "../../static-data/ServiceRequestType";
import axios from "../http-client/axios";
import requestBuilder from "../request-builder/request-builder";
import { METALICA_URL, SCORPIONS_URL } from "../urls/base-urls";
import mapperToMedicalSpecialityRequest from "./medical-specialities-mapper";

export async function getDoctorMedicalSpecialities(crm) {
  const resp = await axios({
    url: `${SCORPIONS_URL}/doctor-specialties?crm=${crm}`,
    method: "get",
    data: {},
  });
  return resp.data;
}

export async function getMedicalSpecialities() {
  const resp = await axios({
    url: `${SCORPIONS_URL}/specialties`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });
  return resp.data;
}

export async function updateMedicalSpeciality(formData) {

  var originalData = mapperToMedicalSpecialityRequest(
    formData.originalData,
    FormRequestAction.update
  );

  var changedData = mapperToMedicalSpecialityRequest(
    formData,
    FormRequestAction.update
  );

  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      originalData,
      TypeRequestedFlow.update,
      [],
      ServiceRequestType.DOCTOR_MEDICAL_SPECIALTIES,
      null,
      changedData
    )
  );
  return resp.data;
}

export async function updateNotificationSpeciality(formData, crm) {
   const resp = await axios.put(
    `${SCORPIONS_URL}/notification/doctor-specialties`,
    {
      "crm": crm,
      "idSpecialty": formData.specialtyId,
      "publicize": formData.publishMedicalGuide ? 'S' : 'N'
    }
  );
  return resp.data;
}

export async function addMedicalSpeciality(formData, attachments) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToMedicalSpecialityRequest(formData, FormRequestAction.insert),
      TypeRequestedFlow.save,
      attachments,
      ServiceRequestType.DOCTOR_MEDICAL_SPECIALTIES
    )
  );
  return resp.data;
}

export async function deleteMedicalSpeciality(formData) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToMedicalSpecialityRequest(formData, FormRequestAction.delete),
      TypeRequestedFlow.delete,
      [],
      ServiceRequestType.DOCTOR_MEDICAL_SPECIALTIES
    )
  );
  return resp.data;
}
