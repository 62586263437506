<template>
  <div>
    <b-row class="title">
      <p class="p4">
        <img src="../../../../../assets/images/MDI-information-outline.svg" />
        Importante: É necessário ter pelo menos 01 e no máximo 02 especialidades
        ativas.
      </p>
    </b-row>

    <div v-show="!this.loading">
      <AccordionList
        titleKeyName="specialtyDescription"
        :items="this.tableItems"
        :itemsFields="this.tableFields"
        :iconName="this.iconName"
        :getCustomTable="this.getCustomTable"
        class="custom-accordion-header"
      />
    </div>
    <div v-show="this.loading">
      <b-skeleton-table
        :rows="tableFields.length"
        :columns="2"
      ></b-skeleton-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AccordionList from "../../../../../components/accordion-list/AccordionList.vue";
import { getDoctorMedicalSpecialities } from "../../../../../services/medical-specialities/medical-specialities-services";
import RegisteredSpecialtiesTable from "../registeredSpecialties/RegisteredSpecialtiesTable.vue";
import { createToast } from "../../../../../components/toast/toast";

Vue.component("occupation-area-table-new", () =>
  import("../registeredSpecialties/RegisteredSpecialtiesTable.vue")
);

export default {
  props: [],
  name: "registered-specialties",
  components: {
    AccordionList,
  },
  mounted() {
    const crm = this.$store.getters.getUserName;
    this.getSpecialties(crm);
  },
  data() {
    return {
      tableFields: [],
      tableItems: [],
      loading: true,
      iconName: [
        { icon: "iconMedicalGuide", tooltip: "Divulgar Guia Médico" },
        { icon: "iconSpecialtyMedical", tooltip: "Especialidade Principal" },
      ],
    };
  },
  methods: {
    async getSpecialties(crm) {
      try {
        this.loading = true;
        const specialties = await getDoctorMedicalSpecialities(crm);

        this.loading = false;
        this.tableItems = specialties.map((me) => {
          me.aborted = false;
          if (me.publishMedicalGuide.toLowerCase() == "s") {
            me.publishMedicalGuide = true;
            me.iconMedicalGuide = "notebook-check-outline.svg";
          } else {
            me.publishMedicalGuide = false;
            me.iconMedicalGuide = null;
          }

          if (me.mainSpecialtyMedical.toLowerCase() == "s") {
            me.mainSpecialtyMedical = true;
            me.iconSpecialtyMedical = "heart-pulse.svg";
          } else {
            me.mainSpecialtyMedical = false;
            me.iconSpecialtyMedical = null;
          }

          return me;
        });
      } catch (error) {
        this.loading = false;
        console.error("getSpecialties", error);
      }
    },
    onEditePublishMedicalGuideHandler(item) {
      item.aborted = false;
      const publishMapArray = this.tableItems
        .map((e) => e.publishMedicalGuide)
        .filter((e) => e == true);

      if (publishMapArray.length <= 0) {
        createToast(
          "Informação",
          "É necessário ter pelo menos 01 especialidade ativa.",
          "info"
        );
        item.publishMedicalGuide = true;
        item.aborted = true;
      } else if (publishMapArray.length > 2) {
        createToast(
          "Informação",
          "É permitido no máximo 02 especialidades ativas.",
          "info"
        );
        item.publishMedicalGuide = false;
        item.aborted = true;
      }
    },
    getCustomTable(item) {
      return (
        <RegisteredSpecialtiesTable
          specialties={item}
          publishEvent={this.onEditePublishMedicalGuideHandler}
        />
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.custom-accordion-header .accordion-header {
  margin-left: 1rem;
}
</style>
