export default function mapperToMedicalSpecialityRequest(
  formData,
  requestAction
) {
  return {
    "Ação da solicitação": requestAction,
    Especialidade: formData.specialtyDescription,
    "Área de atuação": formData.areaDescription,
    "Divulgar Guia Médico": formData.publishMedicalGuide ? "Sim" : "Não",
    "Especialidade Principal": formData.mainSpecialtyMedical ? "Sim" : "Não",
    "RQE": formData.specialtyRqe,
  };
}
